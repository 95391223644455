<template>
  <div class="mb-2">
    <v-data-table hide-default-header :headers="headers" :items="files">
      <template v-slot:[`item.actions`]="{ item }">
        <v-tooltip top transition="fade-transition">
          <template v-slot:activator="{ on }">
            <v-btn icon small @click="deleteFile(item)" v-on="on">
              <v-icon small>{{ $vuetify.icons.values.trash }}</v-icon>
            </v-btn>
          </template>
          <span>{{ $t('labels.delete') }}</span>
        </v-tooltip>
      </template>
    </v-data-table>
    <v-btn depressed :color="hasFiles ? 'default' : 'accent'" @click="addFile">
      {{ $t('labels.add') }}
    </v-btn>
    <v-btn class="ml-4" depressed :color="hasFiles ? 'primary' : 'default'" @click="nextStep" :submitting="submitting">
      {{ $t('labels.next') }}
    </v-btn>
  </div>
</template>

<script>
export default {
  name: 'FilesWidget',
  data: () => ({
    headers: [
      { text: '', value: 'filename', sortable: false },
      { text: '', sortable: false, value: 'actions', align: 'right' }
    ],
    loading: true,
    submitting: false
  }),
  props: {
    files: { type: Array }
  },
  computed: {
    hasFiles() {
      return this.files.length > 0
    }
  },
  methods: {
    deleteFile(file) {
      this.$emit('delete-file', file.id)
    },
    addFile() {
      this.$emit('add-file')
    },
    nextStep() {
      this.$emit('next-step')
    }
  }
}
</script>
