<template>
  <div class="mb-2">
    <v-list dense>
      <template v-for="(i, idx) in notes">
        <v-list-item :key="`notes-${idx}`">
          <v-list-item-avatar>
            <v-icon small v-if="i.private">{{ $vuetify.icons.values.private }}</v-icon>
            <v-icon small v-else>{{ $vuetify.icons.values.public }}</v-icon>
          </v-list-item-avatar>
          <v-list-item-content>
            <v-list-item-title class="notes" v-html="i.text" />
          </v-list-item-content>
          <v-list-item-action>
            <v-btn icon small @click="deleteNote(idx)">
              <v-icon small>{{ $vuetify.icons.values.trash }}</v-icon>
            </v-btn>
          </v-list-item-action>
        </v-list-item>
        <v-divider :key="`notes-${idx}-div`" />
      </template>
    </v-list>
    <v-btn depressed :color="hasNotes ? 'default' : 'accent'" @click="addNote">
      {{ $t('labels.add') }}
    </v-btn>
    <v-btn class="ml-4" depressed :color="hasNotes ? 'primary' : 'default'" @click="nextStep" :submitting="submitting">
      {{ $t('labels.next') }}
    </v-btn>
  </div>
</template>

<script>
export default {
  name: 'NotesWidget',
  props: {
    notes: { type: Array }
  },
  data: () => ({
    submitting: false
  }),
  computed: {
    hasNotes() {
      return this.notes.length > 0
    }
  },
  methods: {
    deleteNote(idx) {
      this.$emit('delete-note', idx)
    },
    addNote() {
      this.$emit('add-note')
    },
    nextStep() {
      this.$emit('next-step')
    }
  }
}
</script>
