<template>
  <VWidget enable-footer-actions>
    <template v-slot:title>
      {{ $t('labels.success') }}
    </template>
    <template v-slot:content>
      {{ $t('successMessage') }}
    </template>
    <template slot="footer-actions">
      <v-btn depressed color="primary" @click="onReset">
        {{ $t('labels.add') }}
      </v-btn>
    </template>
  </VWidget>
</template>

<script>
import { VWidget } from '@argon/app/components'

export default {
  name: 'WizardSuccess',
  components: { VWidget },
  props: {
    onReset: { type: Function, required: true }
  }
}
</script>
<i18n>
{
  "en": {
    "successMessage": "The new deal was successfully created."
  },
  "fr": {
    "successMessage": "Le nouveau dossier a été créé avec succès."
  }
}
</i18n>
