<template>
  <v-container fluid grid-list-md>
    <AppTitleBar>
      <template v-slot:title>{{ $t('title') }}</template>
    </AppTitleBar>
    <WizardSuccess v-if="created" :on-reset="onWizardReset" />
    <component :is="wizard" v-else :on-submitted="onLeadCreated" />

    <router-view></router-view>
  </v-container>
</template>

<script>
import { mapActions } from 'vuex'
import { AppTitleBar } from '@argon/app/components'
import { NAMESPACE } from '../store'
import leadPerimeter from '../perimeters'
import WizardSuccess from '../components/WizardSuccess'
import AdvisorWizard from '../components/AdvisorWizard'
import ManagerWizard from '../components/ManagerWizard'
import BrokerWizard from '../components/BrokerWizard'

export default {
  name: 'IndividualWizard',
  perimeters: [leadPerimeter],
  components: { AppTitleBar, ManagerWizard, AdvisorWizard, BrokerWizard, WizardSuccess },
  data: () => ({
    steps: 1,
    created: false,
    errors: []
  }),
  computed: {
    wizard() {
      if (this.$lead.isAllowed('atLeastManager')) {
        return ManagerWizard
      }
      if (this.$lead.isAllowed('atLeastAdvisor')) {
        return AdvisorWizard
      }
      return BrokerWizard
    }
  },
  methods: {
    ...mapActions(NAMESPACE, ['resetWizard']),
    onLeadCreated() {
      this.created = true
    },
    onWizardReset() {
      this.resetWizard()
      this.created = false
    }
  }
}
</script>

<i18n>
{
  "en": {
    "title": "Quick Lead"
  },
  "fr": {
    "title": "Référence rapide"
  }
}
</i18n>
