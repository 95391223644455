<template>
  <VWidget enable-footer-actions content-class="py-0">
    <template v-slot:title>
      {{ $t('labels.individualDealWizard') }}
    </template>
    <template v-slot:header-actions>
      <v-tooltip top transition="fade-transition">
        <template v-slot:activator="{ on }">
          <v-btn icon small @click="resetWizard" v-on="on">
            <v-icon small v-text="$vuetify.icons.values.reset" />
          </v-btn>
        </template>
        <span>{{ $t('labels.reset') }}</span>
      </v-tooltip>
    </template>
    <template v-slot:content>
      <v-stepper v-model="currentStep" vertical class="elevation-0">
        <v-stepper-step :complete="currentStep > 1" step="1" editable :rules="[() => !hasApplicantErrors]">
          {{ $t('labels.clients') }}
          <small>{{ $t('labels.oneOrMoreClient') }}</small>
        </v-stepper-step>
        <v-stepper-content step="1">
          <ClientsWidget
            :clients="clients"
            :client="client"
            :errors="applicantErrors"
            @on-existing="onExistingClient"
            @add-existing="addExistingClient"
            @create-new="showClientModal"
            @next-step="nextStep"
          />
        </v-stepper-content>

        <v-stepper-step :complete="currentStep > 2" step="2" :editable="hasClients">
          {{ $t('labels.notes') }}
          <small>{{ $t('labels.oneOrMoreNote') }}</small>
        </v-stepper-step>
        <v-stepper-content step="2">
          <NotesWidget :notes="notes" @delete-note="deleteNote" @next-step="nextStep" @add-note="addNote" />
        </v-stepper-content>

        <v-stepper-step :complete="currentStep > 3" step="3" :editable="hasClients">
          {{ $t('labels.files') }}
          <small>{{ $t('labels.oneOrMoreFile') }}</small>
        </v-stepper-step>
        <v-stepper-content step="3">
          <FilesWidget :files="files" @add-file="addFile" @delete-file="deleteFile" @next-step="nextStep" />
        </v-stepper-content>

        <v-stepper-step :complete="currentStep > 4" step="4" :editable="hasClients">
          {{ $t('labels.createdOn') }}
          <small>{{ $t('selectDate') }}</small>
        </v-stepper-step>
        <v-stepper-content step="4">
          <div class="mb-2">
            <DateField date :timezone="timezone" :label="$t('labels.createdOn')" v-model="creationDate" clearable />
            <v-btn depressed color="primary" @click="nextStep" :submitting="submitting">
              {{ $t('labels.next') }}
            </v-btn>
          </div>
        </v-stepper-content>

        <v-stepper-step :complete="currentStep > 5" step="5" :editable="hasClients" :rules="[() => !hasBrokerErrors]">
          {{ $t('labels.broker') }}
          <small>{{ $t('labels.selectBroker') }}</small>
        </v-stepper-step>
        <v-stepper-content step="5">
          <div class="mb-2">
            <BrokerField :label="$t('labels.broker')" v-model="broker" :errors="brokerErrors" />
            <v-btn depressed color="primary" @click="nextStep" :submitting="submitting" v-if="brokerId">
              {{ $t('labels.next') }}
            </v-btn>
          </div>
        </v-stepper-content>

        <v-stepper-step :complete="currentStep > 6" step="6" :editable="hasClients" :rules="[() => !hasAdvisorErrors]">
          {{ $t('labels.advisor') }}
          <small>{{ $t('labels.selectAdvisor') }}</small>
        </v-stepper-step>
        <v-stepper-content step="6">
          <div class="mb-2">
            <AdvisorField :label="$t('labels.advisor')" v-model="advisor" :errors="advisorErrors" />
            <v-btn depressed color="primary" @click="nextStep" :submitting="submitting" v-if="advisorId">
              {{ $t('labels.next') }}
            </v-btn>
          </div>
        </v-stepper-content>
      </v-stepper>
    </template>

    <template slot="footer-actions">
      <v-btn class="ml-4" depressed color="primary" @click="doSubmit" :disabled="!hasClients">
        {{ $t('labels.create') }}
      </v-btn>
    </template>
  </VWidget>
</template>

<script>
import { VWidget, DateField } from '@argon/app/components'
import { BrokerField } from '@/brokers/components'
import { AdvisorField } from '@/advisors/components'
import ClientsWidget from './ClientsWidget'
import NotesWidget from './NotesWidget'
import FilesWidget from './FilesWidget'
import WizardMixin from './WizardMixin'

export default {
  name: 'ManagerWizard',
  mixins: [WizardMixin],
  components: { BrokerField, AdvisorField, VWidget, DateField, ClientsWidget, NotesWidget, FilesWidget },
  created() {
    this.setAdvisor(this.profile.id)
  }
}
</script>

<style lang="scss">
.v-list-item__title.notes p {
  margin: 0 !important;
}
</style>
