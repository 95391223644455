import { mapActions, mapState } from 'vuex'
import { NAMESPACE as ME_NAMESPACE } from '@argon/iam/me/store'
import pick from 'lodash/pick'
import leadPerimeter from '../perimeters'
import { NAMESPACE } from '../store'

export default {
  name: 'WizardMixin',
  perimeters: [leadPerimeter],
  data: () => ({
    submitting: false,
    client: null,
    currentStep: 1,
    errors: []
  }),
  props: {
    onSubmitted: { type: Function, required: true }
  },
  computed: {
    ...mapState(ME_NAMESPACE, ['timezone', 'profile']),
    ...mapState(NAMESPACE, ['clients', 'notes', 'files', 'brokerId', 'advisorId', 'createdOn']),

    broker: {
      get() {
        return this.brokerId
      },
      set(v) {
        this.setBroker(v)
      }
    },
    advisor: {
      get() {
        return this.advisorId
      },
      set(v) {
        this.setAdvisor(v)
      }
    },
    clientId() {
      return this.client ? this.client.id : null
    },
    creationDate: {
      get() {
        return this.createdOn
      },
      set(v) {
        this.setCreatedOn(v)
      }
    },
    applicantErrors() {
      let obj = this.errors.find((x) => x.name === 'applicants')
      return this.clients.map((x, idx) => (obj ? obj.messages[idx] : null))
    },
    hasApplicantErrors() {
      return this.applicantErrors.filter((x) => x !== null).length > 0
    },
    brokerErrors() {
      let obj = this.errors.find((x) => x.name === 'broker_id')
      return obj ? obj.messages : []
    },
    hasBrokerErrors() {
      return this.brokerErrors.length > 0
    },
    advisorErrors() {
      let obj = this.errors.find((x) => x.name === 'advisor_id')
      return obj ? obj.messages : []
    },
    hasAdvisorErrors() {
      return this.advisorErrors.length > 0
    },

    hasClients() {
      return this.clients.length > 0
    },
    hasFiles() {
      return this.files.length > 0
    }
  },
  methods: {
    ...mapActions(NAMESPACE, [
      'createIndividualLead',
      'deleteClient',
      'deleteNote',
      'deleteFile',
      'setBroker',
      'setAdvisor',
      'setCreatedOn',
      'resetWizard',
      'addClient',
      'editClient'
    ]),
    addExistingClient() {
      if (!this.client) {
        return
      }
      const data = pick(this.client, ['firstname', 'lastname', 'contact', 'birthdate', 'language'])
      this.addClient(data).then((resp) => {
        this.client = null
      })
    },
    showClientModal() {
      this.$router.push({ name: 'nestedLeadClient', params: { clientId: 'new' } })
    },
    editClient(v) {
      this.$router.push({ name: 'nestedLeadClient', params: { clientId: v } })
    },
    onExistingClient(v) {
      this.client = v
    },
    addNote() {
      this.$router.push({ name: 'nestedLeadNote', params: this.$route.params })
    },
    addFile() {
      this.$router.push({ name: 'nestedLeadFile', params: this.$route.params })
    },
    nextStep() {
      this.currentStep += 1
    },
    doSubmit() {
      this.submitting = true
      this.createIndividualLead()
        .then(() => {
          this.onSubmitted()
        })
        .catch(({ errors }) => {
          this.errors = errors
        })
        .finally(() => {
          this.steps = 1
        })
    },
    onWizardReset() {
      this.resetWizard()
    }
  }
}
