<template>
  <div class="mb-2">
    <v-row>
      <v-col cols="4" v-for="(i, idx) in clients" :key="`client-${i.id}`">
        <VWidget content-class="pa-0">
          <template v-slot:title>{{ i.id }}</template>
          <template v-slot:header-actions>
            <v-btn icon small @click="editClient(i.id)">
              <v-icon small>{{ $vuetify.icons.values.edit }}</v-icon>
            </v-btn>
            <v-btn icon small @click="deleteClient(i.id)">
              <v-icon small>{{ $vuetify.icons.values.trash }}</v-icon>
            </v-btn>
          </template>
          <template v-slot:content>
            <v-list>
              <v-alert dense dismissible v-if="errors[idx]" tile color="error">{{ errors[idx] }}</v-alert>
              <v-list-item>
                <v-list-item-content>
                  <v-list-item-subtitle>{{ $t('labels.firstName') }}</v-list-item-subtitle>
                  <v-list-item-title>{{ i.firstname }}</v-list-item-title>
                </v-list-item-content>
              </v-list-item>
              <v-list-item>
                <v-list-item-content>
                  <v-list-item-subtitle>{{ $t('labels.lastName') }}</v-list-item-subtitle>
                  <v-list-item-title>{{ i.lastname }}</v-list-item-title>
                </v-list-item-content>
              </v-list-item>
            </v-list>
          </template>
        </VWidget>
      </v-col>
    </v-row>
    <v-row>
      <v-col>
        <v-menu v-model="clientMenu" min-width="450" bottom right :close-on-content-click="false">
          <template v-slot:activator="{ on }">
            <v-btn v-on="on" depressed :color="hasClients ? 'default' : 'primary'">
              {{ $t('labels.add') }}
            </v-btn>
          </template>
          <v-card>
            <v-card-text>
              <ClientField :value="clientId" @input="onExisting" return-object />
            </v-card-text>
            <v-divider />
            <v-card-actions>
              <v-btn color="default" :disabled="!client" depressed @click="addExisting">{{ $t('labels.add') }}</v-btn>
              <v-btn color="default" depressed @click="createNew">{{ $t('labels.new') }}</v-btn>
            </v-card-actions>
          </v-card>
        </v-menu>
        <v-btn class="ml-4" depressed color="primary" @click="nextStep" v-if="hasClients" :submitting="submitting">
          {{ $t('labels.next') }}
        </v-btn>
      </v-col>
    </v-row>
  </div>
</template>

<script>
import { VWidget } from '@argon/app/components'
import { ClientField } from '@/clients/components'

export default {
  name: 'ClientsWidget',
  components: { VWidget, ClientField },
  props: {
    clients: { type: Array },
    client: { type: Object },
    errors: { type: Array }
  },
  data: () => ({
    clientMenu: false,
    submitting: false
  }),
  computed: {
    hasClients() {
      return this.clients.length > 0
    },
    clientId() {
      return this.client ? this.client.id : null
    }
  },
  methods: {
    onExisting(client) {
      this.$emit('on-existing', client)
    },
    addExisting(client) {
      this.$emit('add-existing', client)
      this.clientMenu = false
    },
    createNew() {
      this.$emit('create-new')
      this.clientMenu = false
    },
    nextStep() {
      this.$emit('next-step')
    }
  }
}
</script>
